import { useHorizontalScroll } from '@cyber-cats/uds/hooks';
import { tw } from 'twind';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { Variant } from '__generated__/graphql';
import { useGA4Events } from 'hooks/useGA4Events';
import { AnalyticsEvents, event } from 'utils/analytics';
import { usePageEventsContext } from 'hooks/usePageEventsContext';

import { LeftButton, RightButton } from './DirectionButton';
import { ResponsiveProductImage } from './ResponsiveProductImage';

export const ProductListItemImages = (props: {
  variant: Variant;
  dataTestId?: string;
}) => {
  const t = useTranslate();
  const {
    staticFeatures: { useVerticalMedia },
  } = useSiteConfig();
  const scroller = useHorizontalScroll({ infinite: false });
  const { pageviewEventHasFired } = usePageEventsContext();
  const { fireClickProductImage } = useGA4Events();

  const { orderable, displayOutOfStock, id, name } = props.variant;
  const outOfStock = Boolean(!orderable || displayOutOfStock);

  const scrollerTriggerEvent = entry => {
    if (entry === 'left') scroller.scrollToPrev();
    else if (entry === 'right') scroller.scrollToNext();
    if (pageviewEventHasFired)
      event(AnalyticsEvents.GA4_CustomEvent, {
        event_name: AnalyticsEvents.THUMBNAIL_ARROW,
        event_params: {
          user_action: entry === 'left' ? 'left click' : 'right click',
          item_id_ep: id,
          item_name_ep: name,
        },
      });
  };

  return (
    <div className="relative group" data-test-id={props.dataTestId}>
      <div
        ref={scroller.ref}
        className="flex overflow-x-scroll hover:scroll-snap"
      >
        {props.variant.images.map(image => {
          const src = useVerticalMedia
            ? image.verticalImageHref || image.href
            : image.href;

          return (
            <div key={src} className="w-full flex-none transform-gpu">
              <ResponsiveProductImage
                src={src}
                widths={[300, 350, 400, 450, 500, 550, 600]}
                sizes={{
                  mobile: '50vw',
                  tablet: '33vw',
                  desktop: '25vw',
                }}
                width={400}
                alt={image.alt}
                title={image.alt}
                isVertical={useVerticalMedia}
                className={tw(outOfStock && 'opacity-50')}
                onClick={() => fireClickProductImage(src)}
              />
            </div>
          );
        })}
      </div>
      {outOfStock && (
        <span
          data-test-id="product-list-item-badge"
          className="absolute bottom-5 left-0 uppercase text-xs font-bold bg-white px-2 py-0.5 border-puma-black border-l-2"
        >
          {/* TODO: i18n the display value. */}
          {displayOutOfStock?.displayValue || t('soldOut')}
        </span>
      )}
      <LeftButton
        onClick={() => scrollerTriggerEvent('left')}
        disabled={!scroller.hasPrev}
        label={t('scrollLeft')}
      />
      <RightButton
        onClick={() => scrollerTriggerEvent('right')}
        disabled={!scroller.hasNext}
        label={t('scrollRight')}
      />
    </div>
  );
};
