import { tw } from 'twind/css';
import { useEffect, useMemo } from 'react';

import {
  ProductCarousel as ProductCarouselType,
  RecommendationsOutput,
  Variant,
  VariantProduct,
} from '__generated__/graphql';
import { RecommendationTile } from 'ui/components/RecommendationTile';
import { usePdp } from 'hooks/usePdp';
import { useItemListViewEvent } from 'hooks/useItemListViewEvent';
import { transformListProductToAnalyticsItem } from 'utils/analytics';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useGA4Events } from 'hooks/useGA4Events';

export type ProductGridProps = Omit<ProductCarouselType, 'products'> & {
  header?: string;
  products: Array<VariantProduct | Variant>;
  fromRecommender?: Pick<
    RecommendationsOutput,
    'recommenderId' | 'recommenderName'
  >;
};

export const ProductGrid = ({
  header,
  products,
  fromRecommender,
}: ProductGridProps) => {
  const { currency } = useSiteConfig();
  const pdp = usePdp();
  const listItems = useMemo(() => {
    if (!products) return [];
    return products.map((product, index) => {
      return transformListProductToAnalyticsItem({
        product,
        currency: currency.code,
        quantity: 1,
        categories: {
          item_category: product.primaryCategoryId,
        },
        itemListId: fromRecommender?.recommenderId,
        itemListName: fromRecommender?.recommenderName,
        index,
      });
    });
  }, [
    products,
    currency.code,
    fromRecommender?.recommenderId,
    fromRecommender?.recommenderName,
  ]);

  const itemListId = fromRecommender?.recommenderId || '';
  const itemListName = fromRecommender?.recommenderName || '';

  useItemListViewEvent({
    itemListId,
    itemListName,
    itemIdEp: pdp?.masterProduct?.id,
    itemNameEp: pdp?.masterProduct?.name,
    items: listItems,
  });

  const { product, fireSelectItemEvent } = useGA4Events();

  useEffect(() => {
    if (product) {
      fireSelectItemEvent(itemListId, itemListName);
    }
  }, [product, fireSelectItemEvent, itemListId, itemListName]);

  return (
    <div className="border-t hidden empty:hidden mobile:block tablet:block desktop:block">
      {header && (
        <p
          className="font-bold text-xl sm:text-2xl pb-4 text-uppercase my-4"
          data-test-id="recommend-grid-header-text"
        >
          {header}
        </p>
      )}
      <div
        data-test-id="recommendation-product-list"
        className={tw(
          'grid gap-x-4 gap-y-8 transition-opacity duration-700',
          'grid-cols-2',
          'sm:grid-cols-3',
          'lg:grid-cols-4'
        )}
      >
        {products?.map((product, idx) => (
          <RecommendationTile
            key={product.id}
            containerClassName="h-full justify-between"
            dataTestId="recommended-product-item"
            linkClassName="text-center text-xs"
            product={product}
            fromRecommender={fromRecommender}
            position={idx}
          />
        ))}
      </div>
    </div>
  );
};
