import React, { useRef, useState } from 'react';
import { tw } from 'twind/css';
import { Icon } from '@cyber-cats/uds/elements';

import { YouTubeVideoCarousel as YouTubeVideoCarouselType } from '__generated__/graphql';
import ChevronButton from 'ui/components/ChevronButton';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { YoutubePlayerWithGA4Events } from 'ui/components/YoutubePlayerWithGA4Events';

export const YouTubeVideoCarousel: React.FC<
  YouTubeVideoCarouselType
> = props => {
  const { videoIds } = props;
  const [currentVideo, setCurrentVideo] = useState((videoIds ?? [])[0]);
  const { language } = useSiteConfig();

  const youtubeVideoCarouselRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: props.id,
    name: '',
    creative: props.__typename || 'YouTubeVideoCarousel',
  };

  usePromotionView(youtubeVideoCarouselRef, gaTrackData, true);

  if (!videoIds?.length) return null;

  const isPlaylist = videoIds.length > 1;

  const youtubePlayerOpts: Parameters<
    typeof YoutubePlayerWithGA4Events
  >[0]['opts'] = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: props.autoplay || currentVideo !== videoIds[0] ? 1 : 0,
      controls: 1,
      modestbranding: 1,
      cc_lang_pref: language,
      hl: language,
    },
  };

  return (
    <section
      data-test-id="youtube-video-carousel"
      ref={youtubeVideoCarouselRef}
    >
      <div className="relative mobile:pt-[56.25%] tablet:pt-[42.8%] desktop:pt-[42.8%]">
        <YoutubePlayerWithGA4Events
          videoId={currentVideo}
          className="absolute inset-0 w-full h-full"
          title="PUMA Video Carousel" // TODO: i18n
          opts={youtubePlayerOpts}
        />
      </div>
      {isPlaylist && (
        <PlaylistControls
          showThumbnails={props.showThumbnails}
          videoIds={videoIds}
          currentVideo={currentVideo}
          setCurrentVideo={setCurrentVideo}
        />
      )}
    </section>
  );
};

type ControlsComponentProps = {
  videoIds: string[];
  currentVideo: string;
  setCurrentVideo: (videoId: string) => void;
};

const PlaylistControls = (
  props: ControlsComponentProps & { showThumbnails: boolean }
) => {
  const ControlsComponent = props.showThumbnails ? Thumbnails : Buttons;

  return (
    <ControlsComponent
      videoIds={props.videoIds}
      currentVideo={props.currentVideo}
      setCurrentVideo={props.setCurrentVideo}
    />
  );
};

const Thumbnails = (props: ControlsComponentProps) => (
  <div className="flex justify-center mt-1 md:mt-3 space-x-1 md:space-x-3 items-center overflow-x-auto">
    {props.videoIds.map(video => (
      <div
        key={video}
        className={tw(
          'relative max-w-24 min-w-16 flex-grow-0 w-full overflow-hidden hover:opacity-100',
          video !== props.currentVideo && 'opacity-80'
        )}
      >
        <div className="pt-[56.25%] w-full"></div>
        <img
          className="absolute inset-0 w-full h-full object-cover object-center"
          src={`https://img.youtube.com/vi/${video}/hqdefault.jpg`}
          onClick={() => props.setCurrentVideo(video)}
          width="80"
          height="45"
        />
        {video === props.currentVideo && (
          <div className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] rounded-full overflow-hidden">
            <Icon name="play-outline" size="xl" />
          </div>
        )}
      </div>
    ))}
  </div>
);

const Buttons = ({
  videoIds,
  currentVideo,
  setCurrentVideo,
}: ControlsComponentProps) => {
  const videoIndex = videoIds.indexOf(currentVideo);
  const isFirstVideo = videoIndex === 0;
  const isLastVideo = videoIndex === videoIds.length - 1;

  const goToPreviousVideo = () => setCurrentVideo(videoIds[videoIndex - 1]);
  const goToNextVideo = () => setCurrentVideo(videoIds[videoIndex + 1]);

  return (
    <div className="w-full flex mt-4">
      <div className="flex mt-4">
        <ChevronButton
          className="transform -translate-y-1/2 mr-6"
          disabled={isFirstVideo}
          direction="left"
          onClick={goToPreviousVideo}
        />
        <ChevronButton
          className="transform -translate-y-1/2"
          disabled={isLastVideo}
          direction="right"
          onClick={goToNextVideo}
        />
      </div>
      <div className="ml-auto" data-test-id="video-counter">
        <span>{`${videoIndex + 1} `}</span>
        <span className="inline-block bg-black h-px my-1 mx-2.5 w-7"></span>
        <span>{` ${videoIds.length}`}</span>
      </div>
    </div>
  );
};
