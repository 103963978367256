import { gql } from 'urql';

export const SUGGESTIONS_QUERY = gql`
  query Suggestions($searchTerm: String!, $preview: PreviewInput) {
    suggestions(searchTerm: $searchTerm, preview: $preview) {
      suggestedProductsSummaries {
        id
        masterId
        colorValue
        name
        images {
          href
          alt
        }
        price
        salePrice
      }
      suggestedTerms {
        suggestion
        original
      }
      suggestedPhrases {
        suggestion
      }
      suggestedRedirect
    }
  }
`;
