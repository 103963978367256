import { Text } from '@cyber-cats/uds/elements';
import React, { CSSProperties, useEffect, useState, useMemo } from 'react';

import { SizeGroup, useCategoryByIdQuery } from '__generated__/graphql';
import { usePdp } from 'hooks/usePdp';
import { isServer } from 'utils/constants';
import { constructCategoryPath } from 'utils/constructCategoryPath';

import { SizeGroupOptions } from './SizeGroupOptions';
import { SizeOptions } from './SizeOptions';

type SizeGroupsProps = {
  sizeGroups: SizeGroup[];
  layoutStyle: CSSProperties;
  desiredSize?: string;
  showOnlyOrderableSizes: boolean;
  onChange?: (selectedSize: string) => void;
};

export const SizeGroups = ({
  sizeGroups,
  layoutStyle,
  desiredSize,
  showOnlyOrderableSizes,
  onChange,
}: SizeGroupsProps) => {
  const { referrerCategoryId } = usePdp() || {};
  const variableId = referrerCategoryId || '';

  const [categoryByIdQuery] = useCategoryByIdQuery({
    variables: {
      id: variableId,
    },
    pause: !variableId || isServer,
  });

  const categoryById = categoryByIdQuery.data?.categoryById;
  const path = constructCategoryPath(categoryById);

  const groupContainingDesiredSize = useMemo(() => {
    return (
      sizeGroups.find(group =>
        group.sizes.some(size => size.value === desiredSize)
      ) ??
      (path && path.length > 0 && path[0].label === 'Women'
        ? sizeGroups[1]
        : sizeGroups[0])
    );
  }, [sizeGroups, desiredSize, path]);

  useEffect(() => {
    setSelectedGroupName(groupContainingDesiredSize?.label ?? '');
  }, [groupContainingDesiredSize]);

  const [selectedGroupName, setSelectedGroupName] = useState<string>(
    groupContainingDesiredSize?.label ?? ''
  );

  const selectedGroup =
    sizeGroups.find(x => x.label === selectedGroupName) ?? sizeGroups[0];

  return (
    <>
      <div>
        <SizeGroupOptions
          names={sizeGroups.map(x => x.label)}
          selectedName={selectedGroupName}
          onChange={setSelectedGroupName}
        />
        {selectedGroup.description && (
          <Text size="sm" color="muted" className="block pt-3">
            {selectedGroup.description}
          </Text>
        )}
      </div>
      <SizeOptions
        key={selectedGroup.label}
        sizes={selectedGroup.sizes}
        layoutStyle={layoutStyle}
        desiredSize={desiredSize}
        showOnlyOrderableSizes={showOnlyOrderableSizes}
        onChange={onChange}
      />
    </>
  );
};
