import React from 'react';
import { Heading } from '@cyber-cats/uds/elements';

import { Markdown } from 'ui/elements/Markdown';

export type ManageHeadingTag = 'h1' | 'h2' | 'h3' | 'h4';

type ContentHeadingProps = {
  header: string;
  className?: string;
  headingTag?: ManageHeadingTag | string | null;
  dataTestId?: string;
};

export const ContentHeading: React.FC<ContentHeadingProps> = ({
  header,
  className,
  headingTag,
  dataTestId,
  children,
}) => {
  const DEFAULT_HEAD_TAG = 'h4';
  const HeadingTag = headingTag ?? DEFAULT_HEAD_TAG;

  return (
    <Heading>
      {React.createElement(
        HeadingTag,
        { className, 'data-test-id': dataTestId },
        <>
          <Markdown content={header} />
          {children}
        </>
      )}
    </Heading>
  );
};
