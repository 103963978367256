import { useEffect, useState } from 'react';

import { useGA4Events } from './useGA4Events';

// This hook is designed to trigger a GA4 event when a user clicks on a product
export const useProductClickHandling = (
  itemListId: string,
  itemListName: string
) => {
  const { fireSelectItemEvent, product } = useGA4Events();
  const [isProductClicked, setIsProductClicked] = useState(false);

  useEffect(() => {
    if (product && isProductClicked) {
      fireSelectItemEvent(itemListId, itemListName);
      setIsProductClicked(false);
    }
  }, [
    product,
    isProductClicked,
    fireSelectItemEvent,
    itemListId,
    itemListName,
  ]);

  return setIsProductClicked;
};
