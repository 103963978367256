import { useRef } from 'react';

import {
  BodyHeader as BodyHeaderType,
  GenericTextSize,
} from '__generated__/graphql';
import { SLOT_LINK_TYPE } from 'utils/constants';
import { BannerLinkType } from 'utils/types';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

import { LinkOrTag, TagType } from './LinkOrTag';

export const BodyHeader = ({
  id,
  header,
  horizontalAlignment,
  size,
  link,
  __typename,
  _id,
}: BodyHeaderType & {
  link?: BannerLinkType;
  _id?: string;
}) => {
  const Tag = (
    {
      [GenericTextSize.Large]: 'h1',
      [GenericTextSize.Medium]: 'h2',
      [GenericTextSize.Small]: 'h3',
    } as const
  )[size || GenericTextSize.Small];

  const headingSize = (
    {
      [GenericTextSize.Large]: { initial: 'sm', sm: 'base', md: 'lg' },
      [GenericTextSize.Medium]: { initial: 'xs', sm: 'sm', md: 'base' },
      [GenericTextSize.Small]: { initial: 'xxs', sm: 'xs', md: 'sm' },
    } as const
  )[size || GenericTextSize.Small];

  const bodyHeaderRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: id || '',
    name: '',
    creative: __typename || 'BodyHeader',
    promotion_id: _id,
  };
  usePromotionView(bodyHeaderRef, gaTrackData, true);

  return (
    <LinkOrTag
      dataTestId="body-header"
      href={link?.link}
      query={link?.query}
      linkType={SLOT_LINK_TYPE.LINK}
      tag={TagType.H1}
      ref={bodyHeaderRef}
      headingSize={headingSize}
    >
      <Tag className={`text-${horizontalAlignment}`}>{header}</Tag>
    </LinkOrTag>
  );
};
