import { LegacyRef, memo } from 'react';
import { tw } from 'twind';

import {
  Slot,
  ComponentWithFilters as GqlComponentWithFilters,
  Maybe,
  FeaturesGrid as FeaturesGridType,
} from '__generated__/graphql';
import { useInView } from 'hooks/useInView';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { BodyContent } from 'ui/content/BodyContent';
import { BodyHeader } from 'ui/content/BodyHeader';
import { SplitHero } from 'ui/content/SplitHero';
import { FullBleedHero } from 'ui/content/FullBleedHero';
import { FeatureHero } from 'ui/content/FeatureHero';
import { VideoHero } from 'ui/content/VideoHero';
import { ProductCarousel } from 'ui/content/ProductCarousel';
import { ProductComparison } from 'ui/content/ProductComparison';
import { FeaturesGrid } from 'ui/content/FeaturesGrid';
import { ProductGroupFeatures } from 'ui/content/ProductGroupFeatures';
import { QuoteCallout } from 'ui/content/QuoteCallout';
import { SideBySide } from 'ui/content/SideBySide';
import { TrendingInline } from 'ui/content/TrendingInline';
import { YouTubeVideoCarousel } from 'ui/content/YouTubeVideoCarousel';
import { StyliticsCarousel } from 'ui/content/StyliticsCarousel';
import { ProductRecommender } from 'ui/content/ProductRecommender';
import { LaunchCalendar } from 'ui/content/LaunchCalendar';
import { SignUpHeroBanner } from 'ui/content/SignUpFormHero';
import { InfoBanner } from 'ui/content/InfoBanner';
import { PixleeSimpleWidget } from 'ui/content/PixleeSimpleWidget';
import { Iframe } from 'ui/content/Iframe';
import { AdditionalContentSection } from 'ui/content/AdditionalContentSection';
import { AutomatedProductComparison } from 'ui/content/AutomatedProductComparison';
import { Quilt } from 'ui/content/Quilt';
import { HowToWearIt } from 'ui/content/HowToWearIt';
import { ComponentWithFilters } from '__generated__/graphql-sanity';
import { ManageHeadingTag } from 'ui/content/ContentHeading';

const Components = {
  AdditionalContentSection,
  AutomatedProductComparison,
  BodyContent,
  BodyHeader,
  FeatureHero,
  FeaturesGrid,
  FullBleedHero,
  Iframe,
  InfoBanner,
  PixleeSimpleWidget,
  ProductCarousel,
  ProductComparison,
  ProductGroupFeatures,
  ProductRecommender,
  LaunchCalendar,
  QuoteCallout,
  Quilt,
  SideBySide,
  SignUpForm: SignUpHeroBanner,
  SplitHero,
  StyliticsCarousel,
  HowToWearIt,
  TrendingInline,
  VideoHero,
  YouTubeVideoCarousel,
};

const Missing = component => {
  const { env } = useSiteConfig();
  if (env.stage === 'production') return null;
  return (
    <div className="bg-gray-100 py-24 text-center">
      <h1 className="text-2xl font-bold">
        {component.__typename || component._type}
      </h1>
      <p className="uppercase opacity-50">Missing component for this type</p>
    </div>
  );
};

export const getComponent = (typename: keyof typeof Components) =>
  Components[typename] || Missing;

const InView = ({ i, data }) => {
  const [ref, inView] = useInView();
  const Component = getComponent(data.__typename || data._type);
  if (inView || i === 0) return <Component priority={i === 0} {...data} />;
  return (
    <div ref={ref as LegacyRef<HTMLDivElement>} className="min-h-[50vh]" />
  );
};

const MemoizedInView = memo(InView, (oldProps, newProps) => {
  return oldProps.i === newProps.i || oldProps.data.id === newProps.data.id;
});

export const getComponentForContent = (
  data: Maybe<{
    component?: Maybe<
      (Slot | ComponentWithFilters['component']) & {
        campaignId?: string;
        headingTag?: ManageHeadingTag;
        id?: Maybe<string>;
        _id?: Maybe<string>;
        _type?: Maybe<string>;
      }
    >;
    categoryId?: string;
    productId?: string;
    targetDevices?: Maybe<Array<Maybe<string>>>;
  }>,
  i: number,
  items: Maybe<GqlComponentWithFilters | ComponentWithFilters>[],
  slotId: string,
  headersObject: Record<string, ManageHeadingTag>
) => {
  if (!data?.component) return null;

  const { component } = data;

  const campaign = items[i]?.campaign;
  if (campaign) {
    component.campaignId = campaign[0]?.puid?.current || '';
  }

  const responsiveClasses = data.targetDevices?.map(device => {
    const classes = `${device}:block`;
    return classes;
  });

  const id = component.id || component._id;
  const type = component.__typename || component._type;

  component._type === 'FeaturesGrid'
    ? (component as FeaturesGridType).featureRows.forEach(
        featureRow =>
          (featureRow['headingTag'] = headersObject[featureRow['_key']])
      )
    : (component.headingTag =
        headersObject[(items[i] as GqlComponentWithFilters).id]);

  return (
    <div
      key={`${id}_${i}`}
      id={slotId && `${slotId}-${i}`}
      data-id={id}
      data-type={type}
      className={tw('hidden empty:hidden', responsiveClasses)}
    >
      <MemoizedInView i={i} data={component} />
    </div>
  );
};
