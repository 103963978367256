import { css, tw, apply } from 'twind/css';

import { buildProductUrl } from 'utils/buildProductUrl';
import { Link } from 'ui/elements/Link';
import {
  RecommendationsOutput,
  useTrackRecommendationClickMutation,
  Variant,
  VariantProduct,
} from '__generated__/graphql';
import { GaTrackData } from 'hooks/usePromotionView';
import { useGetRecommenderLocation } from 'hooks/useGetRecommenderLocation';
import { useGA4Events } from 'hooks/useGA4Events';
import { Ga4Data, usePromotionSelect } from 'hooks/usePromotionSelect';
import { setReferrerType } from 'utils/referrerParametersUtils';
import {
  PRODUCT_POSITIONS_LOCAL_STORAGE_KEY,
  REFERRER_TYPE_VAL,
} from 'utils/constants';
import { AnalyticsEvents, event, getProductStyleId } from 'utils/analytics';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { SaleBadge } from './ProductBadge';
import {
  ProductPrice,
  RECOMMENDATION_CAROUSEL_PRICE_STRATEGY,
} from './ProductPrice/ProductPrice';
import { ResponsiveProductImage } from './ResponsiveProductImage';
import { Skeleton } from './Skeleton';

type RecommendationTilePropsType = {
  containerClassName?: string;
  dataTestId?: string;
  linkClassName?: string;
  product: Variant | VariantProduct;
  productInfoConfig?: RecommendationTileProductInfoConfig;
  fromRecommender?: Pick<
    RecommendationsOutput,
    'recommenderId' | 'recommenderName'
  >;
  gaBannerData?: GaTrackData;
  ga4Data?: Ga4Data;
  fetching?: boolean;
  isFromMiniCart?: boolean;
  position?: number;
};

type RecommendationTileProductInfoConfig = {
  containerClassName?: string;
  priceContainerClassName?: string;
  productTitleContainerClassName?: string;
};

export const RecommendationTile = ({
  containerClassName,
  dataTestId = 'recommendation-tile',
  linkClassName,
  product,
  productInfoConfig,
  fromRecommender,
  gaBannerData,
  ga4Data,
  fetching,
  position,
}: RecommendationTilePropsType) => {
  const [, trackRecommendationClick] = useTrackRecommendationClickMutation();
  const recommenderLocation = useGetRecommenderLocation();

  const { setProduct, setItemPosition, fireClickProductImage } = useGA4Events();
  const { promotionSelectEvent } = usePromotionSelect();
  const [productsPositionsInLocalStorage, setProductsPositionsInLocalStorage] =
    useLocalStorage(PRODUCT_POSITIONS_LOCAL_STORAGE_KEY, {});

  if (!product) return null;

  const regularPrice = product.productPrice?.price
    ? product.productPrice.price
    : 0;

  const salePrice = product.productPrice?.salePrice
    ? product.productPrice.salePrice
    : undefined;

  const promotionPrice = product.productPrice?.promotionPrice
    ? product.productPrice.promotionPrice
    : undefined;

  return (
    <Link
      onClick={() => {
        setReferrerType(REFERRER_TYPE_VAL.Carousel);
        if (fromRecommender) {
          trackRecommendationClick({
            masterId: product.masterId,
            swatch: product.colorValue,
            recommenderId: fromRecommender.recommenderId,
            recommenderName: fromRecommender.recommenderName,
          });
          event(AnalyticsEvents.PRODUCT_RECOMMENDER_CLICKED, {
            ecommerce: {
              productRecommenderLocation: recommenderLocation(),
              recommenderName: fromRecommender.recommenderName,
              productName: product.name,
              productSku: product.id,
            },
          });
        }
        if (gaBannerData) {
          event(AnalyticsEvents.PROMOTION_CLICK, {
            ecommerce: {
              promoClick: {
                promotions: [
                  {
                    ...gaBannerData,
                    id: fromRecommender?.recommenderName || gaBannerData.id,
                    'cta-click': 'Banner Clicked',
                  },
                ],
              },
            },
          });
        }
        if (ga4Data) {
          const url = buildProductUrl(product.masterId, product.name, {
            color: product.colorValue,
          });
          promotionSelectEvent({
            ...ga4Data,
            link_url: url,
            product,
          });
        }
        if (product) {
          setItemPosition(position);
          setProductsPositionsInLocalStorage({
            ...productsPositionsInLocalStorage,
            [getProductStyleId(product.masterId, product.colorValue)]: position,
          });
          setProduct(product);
        }
      }}
      href={buildProductUrl(product.masterId, product.name, {
        color: product.colorValue,
      })}
      className={tw(linkClassName, css({ scrollSnapAlign: 'start' }))}
    >
      <div
        className={tw('flex flex-col', containerClassName)}
        data-test-id={dataTestId}
      >
        <div className="relative">
          <SaleBadge variant={product} />
          {product?.images ? (
            <ResponsiveProductImage
              data-test-id={`${dataTestId}-image`}
              className={tw(product.displayOutOfStock && 'opacity-50')}
              alt={product?.images?.[0]?.alt}
              width={400}
              widths={[300, 450, 750, 900, 1050, 1200, 1350, 1500]}
              sizes={{
                mobile: '33vw',
                tablet: '50vw',
                desktop: '25vw',
              }}
              src={product?.images?.[0]?.href}
              onClick={() => fireClickProductImage(product?.images?.[0].href)}
            />
          ) : (
            <Skeleton className="w-full aspect-1-1" />
          )}
          {product.displayOutOfStock && (
            <span
              data-test-id={`${dataTestId}-soldout-badge`}
              className="absolute bottom-0 left-0 uppercase text-xs font-bold bg-white px-2 py-0.5 border-puma-black border-l-2"
            >
              {/* TODO: i18n the display value. */}
              {product.displayOutOfStock.displayValue}
            </span>
          )}
        </div>
        <div
          className={tw(
            apply(
              'h-full flex-1 flex flex-col mt-3 items-start justify-between'
            ),
            productInfoConfig?.containerClassName
          )}
        >
          <h3
            className={tw(
              apply(
                'text-sm desktop:text-base pr-5 line-clamp-2 text-left font-bold'
              ),
              productInfoConfig?.productTitleContainerClassName
            )}
            title={product.name}
            data-test-id={`${dataTestId}-name`}
          >
            {product.name}
          </h3>
          {product.productPrice && !fetching ? (
            <h4
              className={tw(
                apply(
                  'flex flex-col text-sm desktop:text-base items-start relative'
                ),
                productInfoConfig?.priceContainerClassName
              )}
              title={product.name}
            >
              <ProductPrice
                price={{
                  amount: regularPrice,
                  salePrice,
                  promotionPrice,
                }}
                compact={false}
                wrapText
                {...RECOMMENDATION_CAROUSEL_PRICE_STRATEGY}
              />
            </h4>
          ) : (
            <Skeleton className="w-16 h-6" />
          )}
        </div>
      </div>
    </Link>
  );
};
