import React from 'react';

import {
  Maybe,
  SanityImage as SanityImageType,
  SanityVideo,
} from '__generated__/graphql';
import { SanityVideoObjectType } from 'groq/objects/SanityVideoObject';
import { Video } from 'ui/components/Video';
import { SanityImage } from 'ui/content/SanityImage';

export interface VideoOrImageProps {
  header?: Maybe<string>;
  video?: Maybe<SanityVideo> | Maybe<SanityVideoObjectType>;
  mobileBackgroundImage?: Maybe<SanityImageType>;
  image?: Maybe<SanityImageType>;
}

export const VideoOrImage: React.FC<VideoOrImageProps> = props => {
  const { header, video, mobileBackgroundImage, image } = props;
  if (video?.source) {
    const videoOptions = {
      controls: false,
      autoPlay: video.autoplay,
      loop: video.loop,
      muted: video.mute,
    };

    return (
      <Video
        className="absolute inset-0 h-full w-full object-cover object-center"
        src={video.source}
        muted={video.mute}
        videoOptions={videoOptions}
      />
    );
  }
  if (image || mobileBackgroundImage) {
    return (
      <>
        {image && <SanityImage source={image} alt={image?.alt || header} />}
        {mobileBackgroundImage && (
          <SanityImage
            className="hidden mobile:block"
            source={mobileBackgroundImage}
            alt={mobileBackgroundImage?.alt || header}
          />
        )}
      </>
    );
  }

  return null;
};
