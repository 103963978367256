import { Button } from '@cyber-cats/uds/elements';
import { useRef, useState } from 'react';
import { tw } from 'twind';

import { StyliticsLook } from '__generated__/graphql';
import { useStyliticsSanity } from 'hooks/useStyliticsSanity';

import StyliticsOutfitFlyout from './StyliticsOutfitFlyout';

type StyliticsOutfitProps = {
  outfit: StyliticsLook;
  checkIsLastInView: () => void;
};

const StyliticsOutfit = (props: StyliticsOutfitProps) => {
  const { outfit } = props;
  const {
    activeId,
    fireTracking,
    outfitUi: { toggleActiveId },
    carouselUi: { hideCarouselNavBtns },
  } = useStyliticsSanity();
  const isActive = activeId === outfit.id;

  const handleOutfitOpen = () => {
    props.checkIsLastInView();
    hideCarouselNavBtns(true);
    toggleActiveId(outfit.id);
    fireTracking();
  };

  const handleClose = () => {
    toggleActiveId(undefined);
    hideCarouselNavBtns(false);
  };

  return (
    <>
      <div
        className="stylitics-outfit-container"
        style={isActive ? { position: 'absolute' } : {}}
      >
        <div className="stylitics-image-container">
          <div
            className="stylitics-main-image"
            style={{
              backgroundImage: `url(${
                outfit.image.coverImageHref
                  ? outfit.image.coverImageHref
                  : outfit.image.href
              })`,
            }}
            role="img"
            aria-label={outfit.image.alt}
            tabIndex={-1}
            onClick={handleOutfitOpen}
          />
        </div>
        <div className="stylitics-outfit-footer" onClick={handleOutfitOpen}>
          <div
            className={tw('stylitics-outfit-footer-wrapper', {
              invisible: isActive,
            })}
          >
            <Button
              label="View Details"
              variant="underline"
              onClick={handleOutfitOpen}
            />
          </div>
        </div>
      </div>
      {isActive && (
        <StyliticsOutfitFlyout
          isActive={isActive}
          onClose={handleClose}
          outfitId={outfit.id}
        />
      )}
    </>
  );
};

export type StyliticsOutfitContainerProps = {
  index: number;
  outfit: StyliticsLook;
};
const StyliticsOutfitContainer = (props: StyliticsOutfitContainerProps) => {
  const cardRef = useRef(null);
  const {
    activeId,
    outfitUi: { isLastInView },
  } = useStyliticsSanity();
  const [lastInView, setLastInView] = useState(false);
  const anyCardOpen = !!activeId;
  const isActive = activeId === props.outfit.id;

  const checkIsLastInView = () => setLastInView(isLastInView(props.index));

  return (
    <div
      ref={cardRef}
      className={tw('stylitics-card', { active: isActive })}
      data-test-id={'stylitics-outfit-card'}
    >
      <div
        data-test-id="stylitics-carousel-card-wrap"
        className={tw('stylitics-carousel-card-wrap', {
          'is-faded': anyCardOpen && !isActive,
          'slide-left': lastInView && isActive,
        })}
        style={{ height: '100%' }}
      >
        <StyliticsOutfit checkIsLastInView={checkIsLastInView} {...props} />
      </div>
    </div>
  );
};

export default StyliticsOutfitContainer;
