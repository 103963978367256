import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import type { BloomReachData } from '../utils/bloomreach';

import { useSiteConfig } from './useSiteConfig';
import { useAuth } from './useAuth';
import { useBrowsingHistory } from './useBrowsingHistory';
import { useLocation } from './useLocation';

export type BloomreachDataType = {
  cat_id?: string;
  cat?: string;
  search_term?: string;
  prod_id?: string;
  prod_name?: string;
  sku?: string;
  currency?: string;
  is_conversion?: number;
  basket_value?: string | number | null;
  order_id?: string;
  basket?: {
    prod_id?: string;
    sku?: unknown;
    name?: string;
    quantity?: number;
    price?: number;
  }[];
};

export type BloomreachPageType =
  | 'homepage'
  | 'category'
  | 'product'
  | 'content'
  | 'search'
  | 'other';

export const getBloomreachPageType = (
  url: string,
  isCategory: boolean
): BloomreachPageType => {
  switch (true) {
    case isCategory:
      return 'category';
    case url.split('/').length === 3:
      return 'homepage';
    case url.includes('/pd/'):
      return 'product';
    case url.includes('/search'):
      return 'search';
    case url.includes('/help'):
      return 'content';
    default:
      return 'other';
  }
};

const loadPixelScript = (accountId: string, onLoad?: () => void) => {
  (function () {
    const brtrk = document.createElement('script');
    brtrk.type = 'text/javascript';
    brtrk.async = true;
    brtrk.src = `https://cdn.brcdn.com/v1/br-trk-${accountId}.js`;
    if (onLoad) brtrk.onload = onLoad;
    const s = document.getElementsByTagName('script')[0];
    if (s.parentNode) s.parentNode.insertBefore(brtrk, s);
  })();
};

export const useBloomreachPageviewPixel = (
  fetching = false,
  bloomreachData?: BloomreachDataType
) => {
  const router = useRouter();
  const { prevPath } = useBrowsingHistory();
  const { countryCode, bloomreach, env } = useSiteConfig();
  const { location } = useLocation();
  const { customerId } = useAuth();
  const hasFired = useRef<boolean>(false);

  const fireBloomreachPixelEvent = (
    accountId: string,
    objectName: string,
    pageType: string,
    prevPath?: string | null
  ): Promise<void> => {
    if (!bloomreach || !objectName) return Promise.resolve();

    return new Promise(resolve => {
      const br_data: BloomReachData = window[objectName] || {};
      br_data.acct_id = accountId;
      br_data.ptype = pageType;
      br_data.title = document.title;
      br_data.view_id = bloomreach.viewId;
      br_data.user_id = customerId;

      if (env.stage !== 'production') {
        br_data.test_data = true;
      }

      br_data.cat_id = bloomreachData?.cat_id;
      br_data.cat = bloomreachData?.cat;
      br_data.search_term = bloomreachData?.search_term;
      br_data.prod_id = bloomreachData?.prod_id;
      br_data.prod_name = bloomreachData?.prod_name;
      br_data.sku = bloomreachData?.sku;

      br_data.is_conversion = bloomreachData?.is_conversion;
      br_data.basket_value = bloomreachData?.basket_value;
      br_data.order_id = bloomreachData?.order_id;
      br_data.basket = { items: bloomreachData?.basket };

      br_data.customer_country = location.userCountry?.id.toUpperCase();
      br_data.currency = bloomreachData?.currency;
      const fullPrevPath = prevPath
        ? `${window.location.origin}${prevPath}`
        : '';
      br_data.orig_ref_url = fullPrevPath;

      // document.referrer is set to '' when user navigates directly to the site.
      // Set ref to previous path if it exist, otherwise set to document.referrer
      br_data.ref = fullPrevPath ? fullPrevPath : document.referrer;

      const isInitialEvent = !prevPath;
      if (isInitialEvent) {
        window[objectName] = br_data;
        loadPixelScript(accountId, () => resolve());
      } else {
        window.BrTrk?.getTracker()?.updateBrData(br_data);
        window.BrTrk?.getTracker()?.logPageView();

        // in the case of sequentially firing events for different account IOs, we
        // need to ensure that the work has been done for recording the first event
        // before we let the second event fire.
        // a setTimeout of 0 should defer the next event to the next tick
        setTimeout(() => resolve(), 0);
      }
    });
  };

  const resetHasFired = () => (hasFired.current = false);

  useEffect(() => {
    router.events.on('routeChangeComplete', resetHasFired);
    return () => router.events.off('routeChangeComplete', resetHasFired);
  }, [router.events]);

  const pageType = getBloomreachPageType(
    router.asPath,
    !!bloomreachData?.cat_id
  );
  useEffect(() => {
    const shouldFire = !hasFired.current;

    if (
      !shouldFire ||
      !bloomreach ||
      !customerId ||
      !env.bloomreachAccountId ||
      fetching
    )
      return;

    const eventFireOrder = [
      {
        accountId: env.bloomreachSEOAccountId,
        objectName: bloomreach.seoObjectName,
      },
      {
        accountId: env.bloomreachAccountId,
        objectName: bloomreach.objectName,
      },
    ];

    let queue = Promise.resolve();
    for (const { accountId, objectName } of eventFireOrder) {
      if (accountId && objectName) {
        queue = queue.then(() =>
          fireBloomreachPixelEvent(accountId, objectName, pageType, prevPath)
        );
      }
    }

    hasFired.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetching,
    countryCode,
    customerId,
    bloomreachData?.sku,
    bloomreach,
    location,
    env.bloomreachAccountId,
    env.bloomreachSEOAccountId,
    pageType,
    hasFired.current,
  ]);
};
