import { Icon } from '@cyber-cats/uds/elements';
import { tw } from 'twind';

type DirectionButtonProps = {
  direction: 'left' | 'right';
  onClick: () => void;
  disabled: boolean;
  label: string;
};

const DirectionButton = (props: DirectionButtonProps) => {
  return (
    <button
      aria-label={props.label}
      onClick={props.onClick}
      disabled={props.disabled}
      className={tw(
        'mobile:invisible opacity-0 group-hover:opacity-100 absolute top-1/2 transform -translate-y-1/2 w-8 h-8 p-1 items-center justify-center rounded-full bg-white text-puma-black-200 disabled:text-opacity-20',
        `${props.direction}-2`
      )}
      type="button"
    >
      <Icon
        name={props.direction === 'left' ? 'chevron-left' : 'chevron-right'}
      />
    </button>
  );
};

export const RightButton = (props: Omit<DirectionButtonProps, 'direction'>) => {
  return <DirectionButton direction="right" {...props} />;
};

export const LeftButton = (props: Omit<DirectionButtonProps, 'direction'>) => {
  return <DirectionButton direction="left" {...props} />;
};
