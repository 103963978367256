import { HTMLProps, useEffect, useRef } from 'react';

import { VideoPlayerWithGA4Events } from './VideoPlayerWithGA4Events';

export const VideoForVideoHero = ({
  src,
  autoPlay,
  controls,
  muted,
  ...rest
}: HTMLProps<HTMLVideoElement> & { src: string }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const sourceRef = useRef<HTMLSourceElement | null>(null);

  useEffect(() => {
    // We need to manually change the `src`
    // After the initial server render the `src` never changes on the client
    // https://stackoverflow.com/questions/41303012/updating-source-url-on-html5-video-with-react
    if (!sourceRef.current?.getAttribute('src')) {
      sourceRef.current?.setAttribute('src', src);
      videoRef.current?.load();
    }
  }, [src]);

  useEffect(() => {
    // https://github.com/facebook/react/issues/10389
    if (muted) videoRef.current?.setAttribute('muted', '');
  }, [muted]);

  return (
    <VideoPlayerWithGA4Events
      key={src}
      className="w-full"
      onContextMenu={e => e.preventDefault()}
      autoPlay={autoPlay}
      controls={controls}
      controlsList="nodownload nofullscreen"
      disablePictureInPicture
      disableRemotePlayback
      muted={muted}
      {...rest}
      ref={videoRef} // NOTE: important! `ref={}` must to be after {...rest}
    >
      <source key={src} ref={sourceRef} />
    </VideoPlayerWithGA4Events>
  );
};
