import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Maybe } from '__generated__/graphql';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { getUrlFromLocale, removeLocalePrefix } from 'utils/locale';
import { parseHtml } from 'utils/html';
export const PageHead: React.FC<{
  title: Maybe<string> | undefined;
  description?: Maybe<string> | undefined;
  noIndex?: Maybe<boolean> | undefined;
}> = props => {
  const { asPath } = useRouter();
  const { host } = useSiteConfig();
  const { supportedLocales, locale } = useSiteConfig();

  const hostIndexBlackList = [
    'web.development.cybercat.puma.com',
    'web.staging.cybercat.puma.com',
    '.production.cybercat.puma.com',
    'web.nitro.development.xetic.puma.com',
    'web.nitro.staging.xetic.puma.com',
    'pre-web.nitro.development.xetic.puma.com',
    '.nitro.production.xetic.puma.com',
  ];
  const isIndexableHost = hostIndexBlackList.some(baseUrl =>
    host.includes(baseUrl)
  );

  const path = removeLocalePrefix(asPath, locale);
  const robotsContent =
    props.noIndex || isIndexableHost ? 'noindex, nofollow' : 'index, follow';

  const sanitizedDescription = parseHtml(props.description!);

  return (
    <Head>
      {props.title && (
        <>
          <title>{props.title}</title>
          <meta property="twitter:title" content={props.title} />
        </>
      )}
      {props.description && (
        <>
          <meta
            name="description"
            content={sanitizedDescription?.textContent || ''}
          />
          <meta
            property="og:description"
            content={sanitizedDescription?.textContent || ''}
          />
        </>
      )}
      <meta name="robots" content={robotsContent} />
      {/* Sites with multiple languages (US & CA) */}
      {supportedLocales.length > 1 &&
        supportedLocales.map((locale: string) => {
          const [lang, country] = locale.split('-');
          const hrefLang = `${lang}-${country.toUpperCase()}`;
          const localeUrl = getUrlFromLocale(locale);
          return (
            <link
              key={hrefLang}
              rel="alternate"
              hrefLang={hrefLang}
              href={`https://${country}.puma.com${localeUrl}${path}`}
            />
          );
        })}
      {props.children}
    </Head>
  );
};
