import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { UseQueryState, useQuery } from 'urql';
import {
  Container,
  Heading,
  Stack,
  Text,
  SearchForm as UDSSearchForm,
} from '@cyber-cats/uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { QueryContentArgs, SearchSuggestions } from '__generated__/graphql';
import { SUGGESTIONS_QUERY } from 'gql/queries/suggestions';
import { useFeature } from 'hooks/useFeature';
import { ContentResponse, useGroqQuery } from 'hooks/useGroqQuery';
import {
  NotFoundPageContent,
  NotFoundPageQuery,
} from 'groq/pages/NotFoundPage';
import usePageview from 'hooks/usePageview';
import {
  AnalyticsCustomEvent,
  AnalyticsEvents,
  SearchType,
  searchAnalyticsEvent,
} from 'utils/analytics';

import { SanityContentSlot } from './SanityContentSlot';
import { PageHead } from './PageHead';
import { CustomerSupportBanner } from './CustomerSupportBanner';
import { PageLoadingSpinner } from './Page';

export const SearchForm = () => {
  const t = useTranslate();
  const router = useRouter();

  const {
    localizeUrlPath,
    searchSymbolsMinValue,
    charactersNeededToSubmitSearch,
  } = useSiteConfig();

  const showSearchTipsOnError = useFeature('SHOW_SEARCH_TIPS_ON_ERROR');

  const [input, setInput] = useState((router?.query?.q as string) || '');

  const [submitted, setSubmitted] = useState(false);
  const [searchQuery] = useQuery<{
    suggestions: SearchSuggestions;
  }>({
    query: SUGGESTIONS_QUERY,
    pause: input.length < searchSymbolsMinValue,
    variables: {
      searchTerm: input.trim(),
      preview: {
        viewAt: router.query.viewAt as string,
        customerGroups: (router.query.customerGroups as string)?.split(','),
        global: router.query.global === 'true',
      },
    },
  });

  useEffect(() => {
    if (submitted && !searchQuery.fetching) {
      window.sessionStorage.setItem('search_type', SearchType.noResults);
      window.sessionStorage.setItem(
        'suggested_section_click',
        'enter/search button'
      );
      window.sessionStorage.setItem('search_term', input);
      const redirect = searchQuery.data?.suggestions?.suggestedRedirect;
      if (redirect) {
        window.sessionStorage.setItem('user_action', 'redirect');
        searchAnalyticsEvent('0');
        if (redirect.startsWith('https')) {
          window.location.href = redirect;
        } else {
          router.replace(localizeUrlPath(redirect));
        }
      } else if (input.length >= charactersNeededToSubmitSearch) {
        window.sessionStorage.setItem('user_action', 'noredirect');
        router.push(localizeUrlPath(`/search?q=${input}`));
      }
      setSubmitted(false);
    }
  }, [submitted, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container size="sm" asChild>
      <Stack>
        {showSearchTipsOnError && (
          <Text asChild size="lg">
            <p>{t('searchTipsTitle')}</p>
          </Text>
        )}

        <UDSSearchForm
          name="noResultSearch"
          label={t('searchPuma')}
          placeholder={t('searchPuma')}
          value={input}
          dataTestId="search-box-noresults"
          onChange={value => setInput(value)}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            setSubmitted(true);
          }}
        />

        {showSearchTipsOnError && (
          <Text asChild size="base">
            <p>{t('searchTips', undefined, { supportHTMLTags: true })}</p>
          </Text>
        )}
      </Stack>
    </Container>
  );
};

export const NoSearchResults = ({ message }: { message?: string }) => {
  const [pageContent] = useGroqQuery({
    operationName: 'NotFoundPage',
    query: NotFoundPageQuery,
  });
  usePageview(pageContent.fetching);
  return <RawNoSearchResults message={message} pageContent={pageContent} />;
};

export const NoAnalyticsNoSearchResults = ({
  message,
}: {
  message?: string;
}) => {
  const [pageContent] = useGroqQuery({
    operationName: 'NotFoundPage',
    query: NotFoundPageQuery,
  });
  return <RawNoSearchResults message={message} pageContent={pageContent} />;
};

export const RawNoSearchResults = ({
  message,
  pageContent,
}: {
  message?: string;
  pageContent: UseQueryState<
    ContentResponse<NotFoundPageContent>,
    QueryContentArgs
  >;
}) => {
  const t = useTranslate();

  const { pageTitle, pageDescription } = pageContent.data?.content?.seo ?? {};
  const { heading, content } = pageContent.data?.content ?? {};

  const title = message ? message : heading;
  if (heading && !pageContent.fetching) {
    AnalyticsCustomEvent({
      event_name: AnalyticsEvents.SEARCH_OPEN,
      search_type: SearchType.noResults,
    });
  }

  if (pageContent.fetching) return <PageLoadingSpinner />;

  return (
    <>
      <PageHead
        title={t('pageTitleReduced', {
          title: pageTitle || t('notFoundOops'),
        })}
        description={pageDescription}
      ></PageHead>
      <Container size="page">
        <Stack
          gap={{ _: '2xl', lg: '3xl' }}
          className=" mt-10! md:mt-10! lg:mt-14!"
        >
          <Heading
            asChild
            size={{ _: 'lg', sm: 'xl', md: '2xl' }}
            dataTestId="not-found-error-msg"
            className="text-center"
          >
            <h1>{title}</h1>
          </Heading>
          <SearchForm />
          <SanityContentSlot
            items={content}
            documentId="error404"
            slotId="content"
            className="gap-8 md:gap-12 lg:gap-16"
          />
        </Stack>
      </Container>
      <CustomerSupportBanner />
    </>
  );
};
