import { FC, useRef } from 'react';
import { apply, tw } from 'twind/css';

import { useResizeObserver } from 'hooks/useResizeObserver';
import { CallToActions } from 'ui/content/CallToActions';
import { Container } from 'ui/elements/LayoutContainer';
import { alignmentToFlexDirection } from 'utils/alignmentToFlexDirection';
import { Markdown } from 'ui/elements/Markdown';
import { getIsMobile } from 'utils/media';
import { whiteToBlack } from 'utils/whiteToBlack';
import { VideoHero as VideoHeroType } from '__generated__/graphql';
import { Ga4Data } from 'hooks/usePromotionSelect';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { VideoForVideoHero } from 'ui/components/VideoForVideoHero';

import { ContentHeading, ManageHeadingTag } from './ContentHeading';

export const VideoHero: FC<
  VideoHeroType & { headingTag?: ManageHeadingTag }
> = props => {
  const videoHeroRef = useRef<any>(null);
  const { width } = useResizeObserver(videoHeroRef);
  const isMobile = getIsMobile();

  const tAlign = props.horizontalAlignment || 'left';
  const hAlign = alignmentToFlexDirection(props.horizontalAlignment || 'left');
  const vAlign = alignmentToFlexDirection(props.verticalAlignment || 'middle');

  const { header, copy, footnote, ctas } = props;
  const hasContent = Boolean(header || copy || footnote || ctas?.length);

  const videoSrc =
    isMobile && props.mobileBackgroundVideo
      ? props.mobileBackgroundVideo
      : props.backgroundVideo;

  const shouldMapWhiteCTAsToBlack = !props.overlayContent && isMobile;
  const ctasToRender = shouldMapWhiteCTAsToBlack
    ? ctas?.map(whiteToBlack)
    : ctas;

  const promotion_id = props.id || '';
  const promotion_name = props.header || '';
  const creative_name = props.__typename || 'VideoHero';

  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    videoHeroRef,
    gaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: undefined,
    cta_click: undefined,
  };

  return (
    <Container bleed data-test-id="video-hero">
      <section
        ref={videoHeroRef}
        className={`relative flex flex-col items-${vAlign} justify-${hAlign}`}
      >
        <div className="relative w-full">
          <VideoForVideoHero
            src={videoSrc}
            playsInline
            autoPlay={props.autoplay}
            controls={props.controls}
            loop={props.loop}
            muted={props.mute}
          />
        </div>
        {hasContent && (
          <div
            className={tw(
              apply`pointer-events-none absolute inset-0 p-4 tablet:p-8 desktop:p-12 xl:p-16 flex flex-col items-${hAlign} justify-${vAlign} text-${tAlign}${
                props.textColor && ` text-[${props.textColor}]`
              }`,
              props.horizontalAlignment && `text-${props.horizontalAlignment}`,
              !props.overlayContent &&
                'mobile:(w-full relative items-center text-center text-black)'
            )}
            data-test-id="video-hero-content"
          >
            <div className="text-inherit">
              {props.header && (
                <ContentHeading
                  header={props.header}
                  className={tw(
                    'pointer-events-auto font-bold',
                    props.minAspectRatio === 0.25
                      ? 'mobile:text-2xl tablet:text-3xl desktop:text-4xl xl:text-5xl'
                      : 'mobile:text-3xl tablet:text-4xl desktop:text-5xl xl:text-6xl'
                  )}
                  headingTag={props.headingTag}
                  dataTestId="video-hero-header"
                />
              )}
              {props.copy && (
                <p
                  className="pointer-events-auto mt-1 mobile:text-sm tablet:xs:text-lg desktop:text-xl xl:text-2xl"
                  data-test-id="video-hero-copy"
                >
                  <Markdown content={props.copy} />
                </p>
              )}
              {props.footnote && (
                <p
                  className="pointer-events-auto mobile:text-xs tablet:text-sm desktop:text-base mt-1"
                  data-test-id="video-hero-footnote"
                >
                  <Markdown content={props.footnote} />
                </p>
              )}
            </div>
            <div
              key={'ctas-' + width}
              className={tw(
                `flex flex-wrap gap-2 xs:gap-4 mt-3`,
                `mobile:justify-center justify-${hAlign}`
              )}
              data-test-id="video-hero-ctas"
            >
              <CallToActions ctas={ctasToRender} ga4Data={ga4Data} />
            </div>
          </div>
        )}
      </section>
    </Container>
  );
};
