import { tw } from 'twind/css';
import { useRouter } from 'next/router';

import {
  FullBleedHero as FullBleedHeroType,
  HorizontalAlignment,
  VerticalAlignment,
} from '__generated__/graphql';
import { usePromotionView } from 'hooks/usePromotionView';
import { Ga4Data } from 'hooks/usePromotionSelect';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { CallToActions } from 'ui/content/CallToActions';
import { CountdownClock } from 'ui/content/CountdownClock';
import { SanityImage } from 'ui/content/SanityImage';
import { ImageOverlay, ImageOverlayProps } from 'ui/elements/ImageOverlay';
import { alignmentToFlexDirection } from 'utils/alignmentToFlexDirection';
import { Markdown } from 'ui/elements/Markdown';
import { getIsMobile } from 'utils/media';
import { whiteToBlack } from 'utils/whiteToBlack';
import { Container } from 'ui/elements/LayoutContainer';
import { LinkType, SLOT_LINK_TYPE } from 'utils/constants';
import { slotLink } from 'utils/urls';
import { BannerLinkType } from 'utils/types';

import { LinkOrTag, TagType } from './LinkOrTag';
import { ContentHeading, ManageHeadingTag } from './ContentHeading';

export const FullBleedHero = ({
  backgroundColor = 'transparent',
  backgroundImage,
  backgroundOverlay,
  campaignId,
  copy,
  countdownClock,
  ctas = [],
  linkType,
  link,
  footnote,
  header,
  headingTag,
  horizontalAlignment = 'left',
  id,
  logo,
  logoMobile,
  logoPosition,
  minAspectRatio,
  mobileBackgroundImage,
  mobileBackgroundOverlay,
  overlayContent,
  puid,
  priority,
  textAlignment = 'left',
  textColor = '#ffffff',
  __typename,
  verticalAlignment = 'middle',
}: FullBleedHeroType & {
  priority: boolean;
  headingTag?: ManageHeadingTag;
  link?: BannerLinkType;
  linkType?: LinkType;
  campaignId?: string;
}) => {
  const { asPath } = useRouter();
  const { ref, width } = useResizeObserver();
  const isMobile = getIsMobile();

  const ctasAlign = alignmentToFlexDirection(
    textAlignment as HorizontalAlignment
  );
  const hAlign = alignmentToFlexDirection(
    horizontalAlignment as HorizontalAlignment
  );
  const vAlign = alignmentToFlexDirection(
    verticalAlignment as VerticalAlignment
  );

  const imageDimensions = backgroundImage?.asset?.metadata?.dimensions;
  const mobileImageDimensions =
    mobileBackgroundImage?.asset?.metadata?.dimensions;

  // NOTE: will result in { w: 100, h: 56.25 } as an example
  // as a fraction 56.25/100 simplified is 9/16 giving the 16:9 ratio
  const aspectRatio = {
    w: minAspectRatio !== 0 ? 100 : null,
    h: minAspectRatio !== 0 ? minAspectRatio * 100 : null,
  };

  const imgWidth = aspectRatio?.w || imageDimensions?.width;
  const imgHeight = aspectRatio?.h || imageDimensions?.height;

  const hasContent =
    header ||
    copy ||
    footnote ||
    (ctas && ctas.length > 0) ||
    logo?.asset ||
    logoMobile?.asset;

  const countdownConfig = {
    showLabels: true,
    labelClassName: 'text-xs desktop:text-sm uppercase desktop:font-bold',
    timeUnitClassName: 'text-[32px] desktop:text-[56px] font-normal',
    containerClassName: 'my-6 space-x-8 desktop:space-x-12',
  };

  const promotion_name = header || '';

  const gaTrackData = {
    id: puid?.current || '',
    name: promotion_name,
    creative: 'full-bleed-hero',
    campaign: campaignId || '',
    position: '',
  };

  const navigateTo = slotLink(linkType, ctas, link);

  const promotion_id = id;
  const creative_name = __typename || 'FullBleedHero';

  const extendedGaTrackData = {
    ...gaTrackData,
    promotion_id,
    creative_name,
  };

  const { promotionTrackerPosition } = usePromotionView(
    ref,
    extendedGaTrackData
  );

  if (promotionTrackerPosition) {
    gaTrackData.position = promotionTrackerPosition;
  }

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: promotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: ctas && ctas.length > 0 ? ctas[0].link : '',
    cta_click: undefined,
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <Container bleed data-test-id="full-bleed-hero-container">
      <LinkOrTag
        linkType={linkType!}
        tag={TagType.SECTION}
        ref={ref}
        dataLinkLoc="banner"
        query={navigateTo?.query}
        href={navigateTo?.link}
        newtab={!!navigateTo?.newtab}
        gaBannerData={gaTrackData}
        ga4Data={ga4Data}
        className={tw(
          `bg-[${backgroundColor}]`,
          `relative flex items-${vAlign} justify-${hAlign} inset-0 w-full aspect-${imgWidth}-${imgHeight}`,
          `mobile:(relative flex-col items-center justify-center w-full aspect-${
            mobileImageDimensions?.width || imgWidth
          }-${mobileImageDimensions?.height || imgHeight})`
        )}
      >
        <div className="absolute top-0 left-0 w-full h-full mobile:relative">
          <SanityImage
            priority={priority}
            source={backgroundImage}
            mobileSource={mobileBackgroundImage}
          />
          <ImageOverlay
            {...(backgroundOverlay as ImageOverlayProps)}
            className="hidden desktop:block"
          />
          <ImageOverlay
            {...(mobileBackgroundOverlay as ImageOverlayProps)}
            className="block desktop:hidden"
          />
        </div>
        {hasContent && (
          <div
            className={tw(
              'relative p-6 desktop:p-12 xl:p-16 flex flex-col',
              `text-${textAlignment} items-${ctasAlign} text-[${textColor}]`,
              {
                'mobile:w-full absolute': overlayContent,
                'mobile:(relative items-center text-center)': !overlayContent,
                // if content below and not background-color, use back text
                'mobile:text-black': !overlayContent && !backgroundColor,
              }
            )}
          >
            {header && (
              <ContentHeading
                header={header}
                className={tw(
                  'font-bold leading-tight',
                  'mobile:text-2xl tablet:text-3xl desktop:text-4xl xl:text-5xl'
                )}
                headingTag={headingTag}
              />
            )}
            {copy && (
              <p
                className={tw(
                  'mt-1 leading-tight mobile:text-sm tablet:xs:text-lg desktop:text-xl xl:text-2xl'
                )}
              >
                <Markdown content={copy} />
              </p>
            )}
            {footnote && (
              <p className="mobile:text-xs tablet:text-sm desktop:text-base mt-1">
                <Markdown content={footnote} />
              </p>
            )}
            {countdownClock && (
              <CountdownClock {...countdownClock} config={countdownConfig} />
            )}
            {linkType === SLOT_LINK_TYPE.CTA && (
              <div
                key={'ctas-' + width}
                className={tw(
                  `flex flex-wrap flex-gap-2 xs:flex-gap-4`,
                  !countdownClock && 'mt-6',
                  `justify-center md:justify-${ctasAlign}`
                )}
              >
                <CallToActions
                  ctas={
                    !overlayContent && isMobile ? ctas?.map(whiteToBlack) : ctas
                  }
                  gaBannerData={gaTrackData}
                  ga4Data={ga4Data}
                />
              </div>
            )}
            {(logo?.asset || logoMobile?.asset) && (
              <div
                className={tw(
                  'max-w-32',
                  logoPosition === 'above' ? 'order-[-1] mb-4' : 'order-1 mt-4'
                )}
              >
                {logo?.asset && (
                  <img
                    className="mobile:hidden"
                    src={logo.asset?.url}
                    width={logo.asset?.metadata.dimensions.width}
                    height={logo.asset?.metadata.dimensions.height}
                  />
                )}
                {logoMobile?.asset && (
                  <img
                    className="hidden mobile:block"
                    src={logoMobile.asset?.url}
                    width={logoMobile.asset?.metadata.dimensions.width}
                    height={logoMobile.asset?.metadata.dimensions.height}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </LinkOrTag>
    </Container>
  );
};
