import {
  QuiltRoot,
  QuiltViewport,
  QuiltCell,
  TileRoot,
  TileMedia,
  TileContent,
  TileBody,
  TileHeading,
  TileDescription,
  TileActionList,
  QuiltLayout,
  Link,
  LinkInvBlock,
  LayoutMasonry,
} from '@cyber-cats/uds/elements';
import { tw } from 'twind';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { Ga4Data, usePromotionSelect } from 'hooks/usePromotionSelect';
import { getIsMobile } from 'utils/media';
import {
  isDateBetween,
  isDateBefore,
  IsDateAfter,
} from 'utils/dateCalculations';
import { isNotNullish } from 'utils/isNotNullish';
import {
  QuiltDocumentType,
  QuiltLayoutType,
} from 'groq/documents/QuiltDocument';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

const configure = (media: QuiltLayoutType): QuiltLayout => {
  if (!media?.layout)
    return {
      gap: 'base',
      layout: 'stack',
    };

  if (media.layout.startsWith('masonry')) {
    const [, align] = media.layout.split('-');
    return {
      align: align as LayoutMasonry['align'],
      gap: media.gap,
      layout: 'masonry',
    };
  }

  const [layoutType, columns] = media.layout.split('-');
  return {
    columns: parseInt(columns),
    gap: media.gap,
    layout: layoutType as QuiltLayout['layout'],
    limit: media.limit,
  };
};

const showTile = (availableFrom, availableTo, dateToCheck) => {
  if (isNotNullish(availableFrom)) {
    const startDate = new Date(availableFrom);
    if (isNotNullish(availableTo)) {
      return isDateBetween({
        startDate,
        endDate: new Date(availableTo),
        dateToCheck,
      });
    } else {
      return IsDateAfter({ dateToCheck, startDate });
    }
  } else if (isNotNullish(availableTo)) {
    return isDateBefore({ dateToCheck, endDate: new Date(availableTo) });
  }
  return true;
};

export const Quilt = (props: QuiltDocumentType) => {
  const router = useRouter();
  const dateToCheck = router.query.viewAt
    ? new Date(parseInt(router.query.viewAt as string))
    : new Date();
  const isMobile = getIsMobile();

  const promotion_id = props._id || '';
  const promotion_name = '';
  const creative_name = props._type || 'Quilt';
  const quiltRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    quiltRef,
    gaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    cta_click: undefined,
    fireEventFromPdp: router.asPath.includes('/pd/'),
  };

  return (
    <div ref={quiltRef}>
      <QuiltRoot
        config={{
          mobile: configure(props.mobile),
          tablet: configure(props.tablet),
          desktop: configure(props.desktop),
        }}
      >
        <QuiltViewport
          asChild
          className={tw(
            props.mobile.layout.startsWith('scroll') && 'mobile:pb-6',
            props.tablet.layout.startsWith('scroll') && 'tablet:pb-6',
            props.desktop.layout.startsWith('scroll') && 'desktop:pb-8'
          )}
        >
          <ul>
            {props.items
              .filter(item =>
                showTile(item.availableFrom, item.availableTo, dateToCheck)
              )
              .map((item, i) => {
                return (
                  <QuiltTile
                    {...item}
                    isMobile={isMobile}
                    tileLayout={props.tileLayout}
                    tileMediaRatio={props.tileMediaRatio}
                    inverted={props.inverted}
                    contentJustify={props.contentJustify}
                    ga4Data={ga4Data}
                    key={i}
                  />
                );
              })}
          </ul>
        </QuiltViewport>
      </QuiltRoot>
    </div>
  );
};

const normalizeType = item => {
  switch (item._type) {
    case 'PromoTile':
      return {
        color: item.backgroundColor,
        media: item.isMobile
          ? item.mobileBackgroundImage?.asset ?? item.backgroundImage?.asset
          : item.backgroundImage?.asset,
        heading: item.header,
        description: item.copy,
        title: item.cta?.title,
        link: item.cta?.url || item.cta?.link,
      };
    case 'category':
      return {
        heading: item.name,
        link: item.url,
        color: item.color,
        media: item.image?.asset,
      };
    default:
      return {};
  }
};

const QuiltTile = props => {
  const normalized = normalizeType(props);
  const { promotionSelectEvent } = usePromotionSelect();
  return (
    <QuiltCell asChild>
      <li>
        <TileRoot
          className="h-full"
          fill="media"
          invert={props.inverted}
          layout={props.tileLayout}
        >
          <TileMedia
            className={`bg-[${normalized.color}]`}
            ratio={props.tileMediaRatio}
            vignette={props.tileLayout === 'overlay'}
          >
            <picture>
              <img alt={normalized.media?.alt} src={normalized.media?.url} />
            </picture>
          </TileMedia>
          <LinkInvBlock
            href={normalized.link || ''}
            focusable={!normalized?.link?.title}
            onClick={() =>
              promotionSelectEvent({
                ...props.ga4Data,
                link_url: normalized.link || '',
              })
            }
          />
          <TileContent
            justify={props.contentJustify}
            insetX={
              props.tileLayout === 'stack' &&
              !props.inverted &&
              props.contentJustify !== 'center'
                ? false
                : true
            }
          >
            <TileBody>
              {normalized.heading && (
                <TileHeading balance condensed>
                  {normalized.heading}
                </TileHeading>
              )}
              {normalized.description && (
                <TileDescription balance>
                  {normalized.description}
                </TileDescription>
              )}
            </TileBody>
            {normalized?.title && (
              <TileActionList>
                <Link
                  href={normalized.link || ''}
                  label={normalized?.title}
                  onClick={() =>
                    promotionSelectEvent({
                      ...props.ga4Data,
                      link_url: normalized.link || '',
                    })
                  }
                />
              </TileActionList>
            )}
          </TileContent>
        </TileRoot>
      </li>
    </QuiltCell>
  );
};
