import React, { useMemo, useState, useEffect } from 'react';
import { css, tw } from 'twind/style';

import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import {
  Cta as CtaType,
  LinkVariant,
  Maybe,
  ProductCarousel as ProductCarouselType,
  RecommendationsOutput,
  useVariantsQuery,
  Variant,
  VariantProduct,
} from '__generated__/graphql';
import { RecommendationTile } from 'ui/components/RecommendationTile';
import { GridTemplateColumnConfig } from 'ui/content/ProductRecommender';
import { CallToActions } from 'ui/content/CallToActions';
import { getIsDesktop, getIsMobile } from 'utils/media';
import { usePromotionView, GaTrackData } from 'hooks/usePromotionView';
import { useFeature } from 'hooks/useFeature';
import ChevronButton from 'ui/components/ChevronButton';
import { isServer } from 'utils/constants';
import { ProductObjectType as SanityProduct } from 'groq/global-types';
import { usePdp } from 'hooks/usePdp';
import { useItemListViewEvent } from 'hooks/useItemListViewEvent';
import {
  event,
  AnalyticsCustomEvent,
  AnalyticsEvents,
  transformListProductToAnalyticsItem,
} from 'utils/analytics';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useProductClickHandling } from 'hooks/useProductClickHandling';
import { usePageEventsContext } from 'hooks/usePageEventsContext';

import { ContentHeading, ManageHeadingTag } from './ContentHeading';

const isSanityProduct = (product: any): product is SanityProduct =>
  product._type === 'product';

const transformSanityProduct = (product: SanityProduct) => ({
  id: product.masterId + '_' + product.color,
  masterId: product.masterId,
  variantId: product.productId,
  name: product.productName,
});

export type ProductCarouselProps = Omit<ProductCarouselType, 'products'> & {
  _id?: string;
  _type?: string;
  campaignId?: string;
  cta?: Maybe<CtaType[]>;
  gridTemplateColumnConfig?: GridTemplateColumnConfig;
  inSideBySide?: boolean;
  products: Array<VariantProduct | Variant> | Array<SanityProduct>;
  recommendationsKOP: { id: string | undefined; name: string | undefined };
  fromRecommender?: Pick<
    RecommendationsOutput,
    'recommenderId' | 'recommenderName'
  >;
  stackProductInfo?: boolean;
  isMiniCart?: boolean;
};

export const DEFAULT_MOBILE_PRODUCT_GRID_SIZE =
  'repeat(@{itemCount}, calc(50% - 1rem))';

export const DEFAULT_PRODUCT_GRID_SIZE =
  'repeat(@{itemCount}, calc(50% - 3rem))';

/**
 * Transforms a given grid template into `gridTemplateColumn` Tailwind classes for desktop, tablet, and mobile.
 * Instances of the `'@{itemCount}'` placeholder are replaced with the actual number of items in the carousel.
 * The provided templates should be in the following format: `'repeat(@{itemCount}, calc(50% - 3rem))'`.
 * `'calc(50% - 3rem)'` indicates that 2 items are to be displayed with a 3rem peek of the subsequent item.
 * @param template - The grid template to transform.
 * @param itemCount - The number of items in the carousel.
 */
const transformGridColumnTemplate = (
  template: GridTemplateColumnConfig,
  itemCount: number
) => {
  const replaceItemCount = (str = DEFAULT_PRODUCT_GRID_SIZE) =>
    str.replaceAll('@{itemCount}', itemCount.toString());

  const gridTemplateColumns = {
    desktop: { gridTemplateColumns: replaceItemCount(template.desktop) },
    tablet: { gridTemplateColumns: replaceItemCount(template.tablet) },
    mobile: { gridTemplateColumns: replaceItemCount(template.mobile) },
  };

  return {
    desktop: css(gridTemplateColumns.desktop),
    tablet: css(gridTemplateColumns.tablet),
    mobile: css(gridTemplateColumns.mobile),
  };
};

export const ProductCarousel = ({
  cta,
  gridTemplateColumnConfig,
  header,
  inSideBySide,
  products,
  recommendationsKOP,
  fromRecommender,
  stackProductInfo,
  isMiniCart,
  headingTag,
  ...props
}: ProductCarouselProps & { headingTag?: ManageHeadingTag }) => {
  // it's possible that the data from sanity contains duplicate products, and so
  // we need to ensure we de-duplicate these
  const styleNumbers: string[] = [];
  const uniqueProducts: (VariantProduct | Variant | SanityProduct)[] = [];
  const { pageviewEventHasFired } = usePageEventsContext();

  for (const product of products) {
    const colorValue = isSanityProduct(product)
      ? product.color
      : product.colorValue || /* istanbul ignore next */ product.color;

    const styleNumber = `${product.masterId}_${colorValue}`;

    // already seen this style number; ignore this product
    if (styleNumbers.includes(styleNumber)) continue;

    styleNumbers.push(styleNumber);
    uniqueProducts.push(product);
  }

  const [variantsResult] = useVariantsQuery({
    variables: {
      input: {
        styleNumberIds: styleNumbers,
      },
    },
    pause: products.length === 0 || isServer,
  });

  const { ref, hasPrev, hasNext, next, prev } = useHorizontalScroll({
    infinite: false,
  });

  // whilst the variants are loading, we'll use the products from Sanity to
  // display a skeleton loader for each item
  const items =
    variantsResult.data?.variants ??
    uniqueProducts.map(product =>
      isSanityProduct(product) ? transformSanityProduct(product) : product
    );

  const isDesktop = getIsDesktop();
  const isMobile = getIsMobile();

  const showAllPrices = useFeature('SHOW_ALL_PRICES_AT_ONCE');
  const isSpecificPriceLayout = stackProductInfo || showAllPrices;

  const ctaLinkProps = {
    variant: LinkVariant.White,
  };

  const gridClasses = tw([
    'grid gap-2 desktop:gap-4 overflow-x-auto pb-8 mb-4',
    css({ scrollSnapType: 'x mandatory' }),
    gridTemplateColumnConfig
      ? transformGridColumnTemplate(gridTemplateColumnConfig, items.length)
      : transformGridColumnTemplate(
          {
            mobile: DEFAULT_MOBILE_PRODUCT_GRID_SIZE,
            tablet: DEFAULT_PRODUCT_GRID_SIZE,
            desktop: 'repeat(@{itemCount}, calc(25% - 2rem))',
          },
          items.length
        ),
  ]);

  const productInfoConfig = {
    containerClassName: `mobile:flex-col tablet:flex-col desktop:${
      isSpecificPriceLayout ? 'flex-col' : 'flex-row'
    }`,
    priceContainerClassName: tw(
      `mobile:items-start tablet:items-start desktop:${
        isSpecificPriceLayout ? 'items-start' : 'items-end'
      }`,
      stackProductInfo && 'text-sm'
    ),
    productTitleContainerClassName: tw([
      stackProductInfo && 'text-sm',
      showAllPrices && 'mb-4',
    ]),
  };

  const { currency } = useSiteConfig();
  const pdp = usePdp();

  const itemListId = props._id || fromRecommender?.recommenderId || '';
  const itemListName = props._type || fromRecommender?.recommenderName || '';

  const listItems = useMemo(() => {
    if (!variantsResult.data?.variants || variantsResult.fetching) return [];
    return variantsResult.data?.variants.map((product, index) => {
      return transformListProductToAnalyticsItem({
        product,
        currency: currency.code,
        quantity: 1,
        categories: {
          item_category: undefined,
        },
        itemListId,
        itemListName,
        index,
      });
    });
  }, [
    variantsResult.fetching,
    variantsResult.data?.variants,
    currency.code,
    itemListId,
    itemListName,
  ]);

  const gaTrackData: GaTrackData = {
    id: props.puid?.current || fromRecommender?.recommenderName || '',
    name: header || '',
    creative: 'product-carousel',
    campaign: props.campaignId || '',
    position: '',
  };

  const { promotionTrackerPosition } = usePromotionView(
    ref,
    gaTrackData,
    false,
    true
  );

  useItemListViewEvent({
    itemListId,
    itemListName,
    itemIdEp: pdp?.masterProduct?.id,
    itemNameEp: pdp?.masterProduct?.name,
    items: listItems.map(item => {
      return {
        ...item,
      };
    }),
  });

  // To differentiate in which carousel the click occurred.
  const setIsProductClicked = useProductClickHandling(itemListId, itemListName);

  if (promotionTrackerPosition) {
    gaTrackData.position = promotionTrackerPosition;
  }
  const [indexItems, setIndexItems] = useState(0);
  const scrollerTriggerEvent = entry => {
    if (entry === 'left') {
      if (indexItems > 0) setIndexItems(indexItems - 1);
      prev();
      if (pageviewEventHasFired)
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.THUMBNAIL_ARROW,
          event_params: {
            user_action: 'left click',
            item_id_ep: recommendationsKOP?.id,
            item_name_ep: recommendationsKOP?.name,
          },
        });
    } else if (entry === 'right') {
      if (indexItems < items.length) setIndexItems(indexItems + 1);
      next();
      if (pageviewEventHasFired)
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.THUMBNAIL_ARROW,
          event_params: {
            user_action: 'right click',
            item_id_ep: recommendationsKOP?.id,
            item_name_ep: recommendationsKOP?.name,
          },
        });
    }
  };

  useEffect(() => {
    if (variantsResult.data?.variants)
      ref.current.dispatchEvent(new Event('scroll'));
  }, [variantsResult.data?.variants, ref]);

  return (
    <section
      className="group relative w-full"
      aria-label={header || undefined}
      data-test-id="recommendations"
      onClick={() =>
        isMiniCart &&
        AnalyticsCustomEvent({
          event_name: AnalyticsEvents.MINICART,
          user_action: 'recommended product',
        })
      }
    >
      {header && (
        <ContentHeading
          header={header}
          className={tw(
            'font-bold pb-4 text-uppercase flex justify-between',
            'text-lg sm:text-2xl',
            inSideBySide && 'leading-5 items-center'
          )}
          headingTag={headingTag}
          dataTestId="recommend-header-text"
        >
          {cta ? (
            <CallToActions
              className={`whitespace-nowrap font-normal pr-0 text-${
                isMobile ? 'xs' : 'sm'
              }`}
              ctas={cta}
              linkProps={ctaLinkProps}
              gaBannerData={gaTrackData}
            />
          ) : (
            <div style={{ width: '1em', height: '37px' }} />
          )}
        </ContentHeading>
      )}
      <div
        ref={ref}
        data-test-id="recommendation-product-carousel"
        className={gridClasses}
        onClick={() => setIsProductClicked(true)}
      >
        {items.map((item, idx) => (
          <RecommendationTile
            key={item.id}
            containerClassName="flex-shrink-0 w-full"
            dataTestId="recommended-product-item"
            linkClassName="flex w-full relative"
            fromRecommender={fromRecommender}
            product={item}
            productInfoConfig={productInfoConfig}
            gaBannerData={gaTrackData}
            fetching={variantsResult.fetching}
            isFromMiniCart={isMiniCart}
            position={idx}
          />
        ))}
      </div>
      {hasPrev && isDesktop && (
        <ChevronButton
          className="invisible group-hover:visible mobile:hidden tablet:hidden absolute -left-2 top-1/2 transform -translate-y-1/2"
          direction="left"
          onClick={() => scrollerTriggerEvent('left')}
          invert
        />
      )}
      {hasNext && isDesktop && (
        <ChevronButton
          className="invisible group-hover:visible mobile:hidden tablet:hidden absolute -right-2 top-1/2 transform -translate-y-1/2"
          direction="right"
          onClick={() => scrollerTriggerEvent('right')}
          invert
        />
      )}
    </section>
  );
};
