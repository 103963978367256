import React from 'react';
import { tw } from 'twind/css';
import { Icon } from '@cyber-cats/uds/elements';

import { SanityImage } from 'ui/content/SanityImage';
import {
  AdditionalContentListTextImage,
  Maybe,
  PortableTextWithList,
  SanityImage as SanityImageType,
} from '__generated__/graphql';

type MarkDefs = {
  _key?: string;
  _type?: string;
  href?: string;
  newTab?: Maybe<string>;
}[];

type ListObject = {
  _type: 'list';
  text: string;
  children: ListObject[];
  markDefs?: Maybe<MarkDefs>;
};

const getTextFromBlock = (listEntry: PortableTextWithList): string => {
  let text = '';
  if (
    listEntry.children &&
    listEntry.children[0] &&
    !!listEntry.children[0].text
  ) {
    text = listEntry.children[0].text;
  }
  return text;
};

export const createListObject = (
  listEntry: PortableTextWithList,
  level: number,
  listObject: ListObject,
  start = 0
) => {
  if (level === start) {
    if (!listObject.children) {
      listObject.children = [];
    }
    const text = getTextFromBlock(listEntry);
    listObject.children.push({ _type: 'list', text, children: [] });
  } else {
    if (listObject.children.length === 0) {
      listObject.children.push({ _type: 'list', text: '', children: [] });
    }
    createListObject(
      listEntry,
      level,
      listObject.children[listObject.children.length - 1],
      start + 1
    );
  }
};

export const NestedListComponent = ({
  list,
  level,
}: {
  list: ListObject;
  level: number;
}) => {
  return (
    <ul data-test-id="ptwl-list">
      {list.children &&
        list.children.map((child, childIdx) => (
          <React.Fragment key={`li-${level}-${childIdx}`}>
            <li>{child?.text} </li>
            {child.children.length !== 0 && (
              <NestedListComponent
                list={{ _type: 'list', text: '', children: child.children }}
                level={level + 1}
              />
            )}
          </React.Fragment>
        ))}
    </ul>
  );
};

export const PortableTextWithListComponent = ({
  id,
  blocks,
}: {
  id: string;
  blocks: Array<AdditionalContentListTextImage | null>;
}) => {
  const renderBlocks: Array<
    AdditionalContentListTextImage | null | ListObject
  > = [];
  if (blocks) {
    const lists: Array<ListObject> = [];
    let level = 0;
    let listCount = 0;
    blocks.forEach(blockElement => {
      if (blockElement) {
        if (!!blockElement['_type'] && blockElement['_type'] === 'block') {
          if (!!blockElement['listItem']) {
            if (!lists[listCount]) {
              lists[listCount] = { _type: 'list', text: '', children: [] };
            }
            level = blockElement['level'] as number;
            createListObject(
              blockElement as PortableTextWithList,
              level,
              lists[listCount]
            );
            return;
          }
        }
        if (level !== 0) {
          renderBlocks.push(lists[listCount].children[0]);
          listCount++;
        }
        renderBlocks.push(blockElement);
        level = 0;
      }
    });
  }

  return (
    <>
      {renderBlocks &&
        renderBlocks.map((entry, idx) => {
          let content: ListObject;
          if (!entry) return null;

          if (!!entry['_type'] && entry['_type'] === 'block') {
            content = entry as ListObject;
            const href = content.markDefs ? content.markDefs[0]?.href : '';
            return (
              <p key={`p-${id}-${idx}`} data-test-id="ptwl-paragraph">
                <span>
                  {content.children &&
                    content.children.map((child, childIdx) => (
                      <React.Fragment key={`${id}-${idx}-${childIdx}`}>
                        {content?.markDefs && content?.markDefs?.length > 0 ? (
                          <div className="flex">
                            <a
                              href={href}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {child?.text}
                            </a>
                            <div>
                              <Icon
                                name="external-link"
                                size="xxs"
                                className="inline ml-1"
                              />
                            </div>
                          </div>
                        ) : (
                          <div>{child?.text} </div>
                        )}
                      </React.Fragment>
                    ))}
                </span>
              </p>
            );
          }

          if (!!entry['asset']) {
            return (
              <div
                key={`block-div-${id}-${idx}`}
                className={tw('relative mt-2 h-52')}
                data-test-id="ptwl-asset"
              >
                <SanityImage source={entry as SanityImageType} />
              </div>
            );
          }

          if (!!entry['_type'] && entry['_type'] === 'list') {
            content = entry as ListObject;
            return (
              <NestedListComponent
                key={`nested-list-${id}-${idx}`}
                list={content}
                level={0}
              />
            );
          }

          return null;
        })}
    </>
  );
};
