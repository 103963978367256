import { Icon, IconProps } from '@cyber-cats/uds/elements';
import React from 'react';
import { tw, style } from 'twind/style';

const messageStyle = style({
  base: 'flex-shrink flex items-center space-x-2 text-xs text-puma-black-300',
  variants: {
    type: {
      success: 'text-puma-green',
      warning: 'text-puma-red',
      plaininfo: 'text-puma-black',
      info: 'text-puma-black-300',
    },
  },
});

export const AlertMessage: React.FC<{
  type?: 'warning' | 'success' | 'plaininfo' | 'info';
  icon?: IconProps['name'];
  children: React.ReactNode;
  dataTestId?: string;
}> = props => {
  return (
    <div
      className={tw(
        messageStyle({
          type: props.type,
        })
      )}
      data-test-id={props.dataTestId}
      role="alert"
    >
      {props.icon && <Icon name={props.icon} size="2xl" />}
      <p className="font-bold uppercase text-sm mb-0.5">{props.children}</p>
    </div>
  );
};
