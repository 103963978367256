import { RefObject, useRef, useState } from 'react';

import { isServer } from 'utils/constants';

import { useIntersectionObserver } from './useIntersectionObserver';

export const useInView = (): [RefObject<HTMLElement>, boolean] => {
  const [inView, setInView] = useState(isServer ? true : false);
  const ref: RefObject<HTMLElement> = useRef(null);
  useIntersectionObserver(ref, () => setInView(true), true, {
    threshold: 0.01,
    rootMargin: '0px 0px 20% 0px',
  });
  return [ref, inView];
};
