import React from 'react';

import { LaunchCalendarType } from 'groq/documents/CardsAndTilesDocument';
import { Card } from 'ui/elements/Card';
import { Tile } from 'ui/elements/Tile';

// NOTE - This is confusingly called 'Launch Calendar' as this matches the component name in sanity
// https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/LaunchCalendar.ts#L20
// We actually mean this to be 'Cards and Tiles' which JP still use for their store locator pages
export const LaunchCalendar: React.FC<LaunchCalendarType> = props => {
  return (
    <div className="relative grid gap-8 grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 xl:grid-cols-4">
      {props.calendarContent &&
        props.calendarContent.map(item => {
          switch (item?._type) {
            case 'Card':
              return <Card key={item._key} {...item} />;
            case 'Tile':
              return <Tile key={item._key} {...item} />;
            default:
              return null;
          }
        })}
    </div>
  );
};
