import React from 'react';
import { tw } from 'twind';

import { VideoPlayerWithGA4Events } from './VideoPlayerWithGA4Events';

type ProductVideoOptions = {
  controls: boolean;
  autoPlay: boolean;
  loop: boolean;
  muted: boolean;
};

interface VideoProps extends React.ComponentProps<'video'> {
  className?: string;
  playsInline?: boolean;
  videoOptions: ProductVideoOptions;
}

export type ProductVideo = {
  __typename: string;
  src: string;
  videoOptions: ProductVideoOptions;
  muted?: boolean;
};

export const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  (props, ref) => {
    const {
      src,
      videoOptions,
      className = 'zoom-video',
      playsInline = true,
      muted,
    } = props;

    return (
      <VideoPlayerWithGA4Events
        ref={ref}
        className={tw(className)}
        controls={videoOptions.controls}
        autoPlay={videoOptions.autoPlay}
        loop={videoOptions.loop}
        muted={muted}
        playsInline={playsInline}
        controlsList="nodownload"
      >
        <source src={src} type="video/mp4" data-test-id="video-source" />
      </VideoPlayerWithGA4Events>
    );
  }
);
Video.displayName = 'Video';
