import { ButtonIcon, ButtonIconProps } from '@cyber-cats/uds/elements';
import { forwardRef } from 'react';

import { useTranslate } from 'hooks/useTranslations';

type Props = Partial<Omit<ButtonIconProps, 'ref' | 'icon'>> & {
  direction: 'left' | 'right';
  testId?: string;
};

type DirectionProps = Pick<ButtonIconProps, 'label' | 'icon'>;

const ChevronButton = forwardRef<HTMLButtonElement, Props>(
  function ChevronButton({ direction, testId, ...buttonProps }, ref) {
    const t = useTranslate();

    const defaultTestId = `chevron-button-${direction}`;

    const directionProps: DirectionProps =
      direction === 'left'
        ? { label: t('previous'), icon: 'chevron-left' }
        : { label: t('next'), icon: 'chevron-right' };

    return (
      <ButtonIcon
        dataTestId={testId ?? defaultTestId}
        mode="icon"
        size="sm"
        variant="primary"
        ref={ref}
        {...directionProps}
        {...buttonProps}
      />
    );
  }
);

export default ChevronButton;
