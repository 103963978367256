import { useState } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { ProductMeasurements, SizeChart } from '__generated__/graphql';
import { useFeature } from 'hooks/useFeature';

import { SizeTable } from './SizeTable';

type ProductMeasurementsTableProps = {
  productMeasurements: ProductMeasurements;
  measurementMessages?: SizeChart[];
};

export const ProductMeasurementsTable = ({
  productMeasurements,
  measurementMessages,
}: ProductMeasurementsTableProps) => {
  const t = useTranslate();
  const showMetricAsDefault = useFeature('SHOW_METRIC_AS_DEFAULT');
  const [metric, setMetric] = useState(showMetricAsDefault ?? false);

  const measurementMessagesAsDefault =
    showMetricAsDefault && measurementMessages;

  const unitSwitcher = (
    <div className="uppercase text-xs font-bold cursor-pointer ml-auto">
      <span
        className={`pr-4 ${metric ? 'text-puma-black-400' : ''}`}
        onClick={() => setMetric(false)}
      >
        {t('inches')}
      </span>
      <span
        className={`${!metric ? 'text-puma-black-400' : ''}`}
        onClick={() => setMetric(true)}
      >
        {t('cm')}
      </span>
    </div>
  );

  return (
    <>
      <SizeTable
        title={t('productMeasurements')}
        data={productMeasurements.metric}
        hidden={!metric}
        measurementMessages={measurementMessagesAsDefault || []}
        showMetricAsDefault={showMetricAsDefault}
      >
        {unitSwitcher}
      </SizeTable>
      <SizeTable
        title={t('productMeasurements')}
        data={productMeasurements.imperial}
        hidden={metric}
        measurementMessages={measurementMessagesAsDefault || []}
        showMetricAsDefault={showMetricAsDefault}
      >
        {unitSwitcher}
      </SizeTable>
    </>
  );
};
