import React, { useRef } from 'react';

import { AdditionalContentSection as AdditionalContentSectionType } from '__generated__/graphql';
import { PortableTextWithListComponent } from 'ui/components/PortableTextWithList';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

export function AdditionalContentSection({
  id,
  __typename,
  text,
}: AdditionalContentSectionType) {
  const additionalContentSectionRef = useRef(null);
  const promotionId = id || '';
  const promotionName = '';
  const creativeName = __typename || 'AdditionalContentSection';
  const gaTrackData: GaTrackData = {
    id: promotionId,
    name: promotionName,
    creative: creativeName,
  };
  usePromotionView(additionalContentSectionRef, gaTrackData, true);
  return (
    <>
      {text && (
        <div
          data-test-id="additional-content-section"
          ref={additionalContentSectionRef}
        >
          <PortableTextWithListComponent id={id} blocks={text} />
        </div>
      )}
    </>
  );
}
